const meta = {
    title: "Profesionalių sportininkų pagalba - S-Sportas x Gearro",
    date: "2025-02-20",
    description: "S-Sportas jungiasi prie Gearro, kad sporto entuziastai dar lengviau rastų aukštos kokybės sporto įrangą.",
    author: "Gearro",
    bannerImage: "https://nextvelo-prod.b-cdn.net/blog_pictures/S-Sportas.jpg"
};

const content = `
<article>
    <img src="https://nextvelo-prod.b-cdn.net/blog_pictures/S-Sportas.jpg" alt="Gearro x S-Sportas" />
    <h2>Jei domiesi dviračiais, triatlonu ar žiemos sportu, „S-Sportas“ tau jau girdėtas vardas</h2>
    <p>Tai viena seniausių specializuotų sporto prekių parduotuvių Lietuvoje, kuri ne tik parduoda aukštos kokybės įrangą, bet ir aktyviai dalyvauja sporto bendruomenės gyvenime. Dabar „S-Sportas“ jungiasi prie „Gearro“ – kad sporto entuziastai dar lengviau rastų tai, ko ieško.</p>

    <h2>Neįkainojama patirtis</h2>
    <p>Per daugiau nei 20 metų „S-Sportas“ tapo viena patikimiausių sporto prekių parduotuvių Lietuvoje. Komandoje dirba profesionalūs dviratininkai, triatlonininkai ir orientacininkai, kurie patys naudoja, testuoja ir atrenka geriausią įrangą. Tad jei ieškai patarimo, čia gausi jį iš žmonių, kurie kalba iš savo patirties.</p>

    <h2>Nuo miesto iki bekelės trasų</h2>
    <p>„S-Sportas“ siūlo įvairių tipų dviračius – kalnų, plento, gravel, miesto ir elektrinius. Nesvarbu, ar planuoji kelionę į darbą per miestą, ar ruošiesi rimtam iššūkiui gamtoje – čia galimai rasi tai, kas tau tinka.</p>
    <p>„S-Sportas“ prekiauja visiems gerai žinomais prekių ženklais, tokiais kaip:</p>
    <ul>
        <li>Scott</li>
        <li>Bergamont</li>
        <li>Brinckers</li>
        <li>Classic</li>
        <li>Woom</li>
    </ul>

    <h2>Sporto inventorius visiems metų laikams</h2>
    <p>„S-Sportas“ yra daugiau nei dviračių parduotuvė – čia rasi žiemos sporto įrangą, orientavimosi sporto bei triatlono inventorių. Ši parduotuvė palaiko ne tik atskirus sportininkus, bet ir visą sporto kultūrą, turėdama savo „S-Sport“ komandą, kuri aktyviai dalyvauja varžybose ir renginiuose.</p>
    <p>Jei kada nors svarstei apie triatloną ar orientavimosi sportą, čia tikrai atsiras kas nors, kas tau patars iš patirties.</p>

    <h2>Parduotuvės visoje Lietuvoje</h2>
    <p>Vilniuje, Kaune ir Šiauliuose įsikūrusios „S-Sportas“ parduotuvės leidžia sporto entuziastams gyvai apžiūrėti įrangą, gauti profesionalų patarimą ir išsirinkti labiausiai tinkamą sporto įrangą tau.</p>
    <ul>
        <li><strong>Vilnius</strong> – Ateities g. 33</li>
        <li><strong>Kaunas</strong> – Pramonės pr. 8A</li>
        <li><strong>Šiauliai</strong> – J. Jablonskio g. 16</li>
    </ul>

    <p>👉 Peržiūrėk <a href="https://gearro.lt/user/67b5bc8d-0b24-46a8-8b70-2433fe7b6170">„S-Sportas“ dviračius ir sporto įrangą „Gearro“ platformoje</a>.</p>
</article>
`;

export default { meta, content };
