const meta = {
  title: 'Gearro x Ponas Dviratis – partnerystė su Vilniaus legendine dviračių parduotuve',
  date: '2025-02-20',
  description: 'Džiaugiamės galėdami pristatyti „Gearro“ partnerį – „Ponas Dviratis“, gerai žinomą Vilniaus dviratininkams už kokybišką servisą ir draugišką atmosferą.',
  author: 'Gearro Team',
  bannerImage: 'https://nextvelo-prod.b-cdn.net/blog_pictures/PonasDviratis.jpg',
};

const content = `
<article>
  <img src="https://nextvelo-prod.b-cdn.net/blog_pictures/PonasDviratis.jpg" alt="Gearro x Ponas Dviratis" />
  <h2>Įsikūrę ne tik Vilniaus, bet ir vilniečių širdyse</h2>
  <p>Jei kada nors esi mynęs dviračiu Vilniaus centre, tikėtina, kad apie „Ponas Dviratis“ jau esi girdėjęs. Ši parduotuvė ir servisas – ne šiaip dar viena vieta susitvarkyti dviratį, o erdvė, kur dviračiai gauna tinkamą priežiūrą, o lankytojai – draugišką ir profesionalų aptarnavimą.</p>
  <p>„Ponas Dviratis“ yra įsikūręs <strong>A. Goštauto g. 4</strong>, pačiame miesto centre, todėl čia patogu užsukti tiek vietiniams dviratininkams, tiek miesto svečiams. Ar ieškai naujo dviračio, ar tiesiog pritrūko atsarginės detalės – viską rasi po vienu stogu.</p>

  <h2>Platus dviračių, aksesuarų ir dalių pasirinkimas</h2>
  <p>Parduotuvėje siūlomi įvairių tipų dviračiai, įskaitant:</p>
  <ul>
    <li>Gravel dviračius</li>
    <li>Hibridinius dviračius</li>
    <li>Kalnų dviračius</li>
    <li>BMX dviračius</li>
    <li>Elektrinius dviračius</li>
    <li>Miesto dviračius</li>
  </ul>
  <p>Taip pat čia rasi didelį dviračių dalių ir aksesuarų asortimentą, kuris padės tinkamai pasiruošti bet kokiai kelionei.</p>

  <h2>Profesionalus dviračių servisas ir ilgametė patirtis</h2>
  <p>„Ponas Dviratis“ jau daugiau nei dešimtmetį teikia profesionalias dviračių remonto ir priežiūros paslaugas, pelnydamas dviratininkų pasitikėjimą. Nesvarbu, ar reikia paprasto reguliavimo, ar rimtesnio remonto – jų komanda pasiruošusi padėti.</p>

  <h2>„Pono Dviračio“ talismanas – šuo Trolis</h2>
  <p>Net jei neplanuoji įsigyti naujo dviračio ar tvarkyti senojo, užsukti į parduotuvę vis tiek rekomenduojame. Tave pasitiks ne tik meistrai, bet ir „Pono Dviračio“ talismanas – šuo Trolis. Jis yra neatsiejama parduotuvės dalis ir neoficialus prekinis veidas.</p>

  <h2>Kur rasti?</h2>
  <p><strong>📍 Adresas:</strong> A. Goštauto g. 4-1A, Vilnius</p>
</article>
`;

export default { meta, content };
