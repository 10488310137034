const meta = {
  title: 'Gearro x Velloccino – dviračiai, bendruomenė ir daugiau',
  date: '2025-02-20',
  description: 'Džiaugiamės pristatydami naują Gearro partnerį – Velloccino! Tai ne tik dviračių parduotuvė, bet ir vieta, kur kokybiška įranga, profesionalus aptarnavimas ir dviračių bendruomenė sutelpa po vienu stogu.',
  author: 'Gearro',
  bannerImage: 'https://nextvelo-prod.b-cdn.net/blog_pictures/Vellocino.png',
};

const content = `
<article>
  <img src="https://nextvelo-prod.b-cdn.net/blog_pictures/Vellocino.png" alt="Gearro x Velloccino" />
  <p>🚴 Džiaugiamės pristatydami naują <strong>Gearro</strong> partnerį – <strong>Velloccino</strong>! Tai ne tik dviračių parduotuvė, bet ir vieta, kur kokybiška įranga, profesionalus aptarnavimas ir dviračių bendruomenė sutelpa po vienu stogu.</p>

  <h2>Kokiais dviračiais prekiauja Velloccino?</h2>
  <p><strong>Velloccino</strong> siūlo platų dviračių pasirinkimą, įskaitant:</p>
  <ul>
    <li>Plento dviračius</li>
    <li>Gravel dviračius</li>
    <li>Miesto dviračius</li>
    <li>MTB dviračius</li>
    <li>Triatlono dviračius</li>
    <li>Elektrinius dviračius</li>
    <li>Vaikiškus dviračius</li>
  </ul>
  <p>Jie yra oficialus <strong>Wilier</strong> atstovas Baltijos šalyse, o taip pat prekiauja <strong>Marin, Cervelo, Giant, Early Rider</strong> prekių ženklais.</p>

  <h2>Papildomos paslaugos</h2>
  <ul>
    <li>🚴 <strong>Dviračių nuoma</strong> – galimybė išbandyti aukštos kokybės dviračius prieš perkant ar tiesiog praleisti dieną ant dviračio.</li>
    <li>🛠️ <strong>Dviračių servisas</strong> – profesionali techninė priežiūra, kad tavo dviratis būtų pasiruošęs bet kokiam iššūkiui.</li>
  </ul>

  <h2>Kur rasti Velloccino?</h2>
  <p><strong>Velloccino</strong> turi dvi lokacijas Vilniuje, kurios puikiai atspindi jų dviračių filosofiją:</p>
  <ul>
    <li>📍 <strong>Žalgirio g. 122, Vilnius, Vilnius</strong> – dviračių parduotuvė ir servisas, kur rasi platų naujų ir naudotų dviračių pasirinkimą bei ekspertų patarimus.</li>
    <li>📍 <strong> Aukštaičių g. 12, Vilnius</strong> – unikali vieta Vilniaus širdyje, kur dviračiai dera su kava ir bendruomene. Čia gali ne tik apžiūrėti naujausius dviračių modelius, bet ir paragauti itališkos <em>„Segafredo“</em> kavos bei desertų – energijos užtaisas po pasivažinėjimo dviračiu!</li>
  </ul>

  <h2>Peržiūrėk Velloccino dviračius Gearro platformoje</h2>
  <p>👉 <a href="https://gearro.lt/user/670c2d01-334d-4116-a5a3-a10124669a4a">Atrask Velloccino dviračius Gearro platformoje</a></p>
  <p>🏪 Aplankyk Velloccino – čia dviračiai tampa daugiau nei tik transporto priemone!</p>
</article>
`;

const additionalContent = {
  listingsProfileId: '352',
  sharetribeAccountId: '670c2d01-334d-4116-a5a3-a10124669a4a',
  company: 'velloccino'
}

export default { meta, content, additionalContent };
